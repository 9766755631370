import LOCAL_STORAGE_KEYS from 'core/enum/local-storage.enum';
import { Dispatch, SetStateAction } from 'react';
import { SUBSCRIPTION_DRAWER_TYPE } from 'component/atoms/PremiumSubscriptionDrawer';
import MixpanelTrackingService from 'core/service/mixpanel-tracking.service';
import { IApartment } from '@wohnsinn/ws-ts-lib';

export const handleSubscriptionDrawer = (
  setShowSubscriptionLpDrawer: Dispatch<SetStateAction<boolean>>,
  setShowSubscriptionDrawer: Dispatch<SetStateAction<boolean>>,
  mixpanelTrackingService: MixpanelTrackingService,
  apartment: IApartment
) => {
  const userSubscriptionType = localStorage.getItem(LOCAL_STORAGE_KEYS.SUBSCRIPTION_DRAWER_TYPE);

  const openDrawer = (drawerType: string) => {
    if (drawerType === SUBSCRIPTION_DRAWER_TYPE.LANDING) {
      setShowSubscriptionLpDrawer(true);
      mixpanelTrackingService.trackEvent('OPENED_SUBSCRIPTION_DRAWER', { type: drawerType, ...apartment });
    } else {
      setShowSubscriptionDrawer(true);
      mixpanelTrackingService.trackEvent('OPENED_SUBSCRIPTION_DRAWER', { type: drawerType, ...apartment });
    }
  };

  if (userSubscriptionType) {
    openDrawer(userSubscriptionType);
  } else {
    const randomChoice = Math.random() < 0.5 ? SUBSCRIPTION_DRAWER_TYPE.LANDING : SUBSCRIPTION_DRAWER_TYPE.CHECKOUT;

    openDrawer(randomChoice);
    localStorage.setItem(LOCAL_STORAGE_KEYS.SUBSCRIPTION_DRAWER_TYPE, randomChoice);
  }
};
