import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IApartment, IPaginatedApartmentsListResponse, MATCHING_MODE } from '@wohnsinn/ws-ts-lib';
import { getTenantApplicationState, TENANT_APPLICATION_STATE } from '../helper/get-tenant-application-state';
import LOCAL_STORAGE_KEYS from '../enum/local-storage.enum';
import { MODAL_IDENTIFIER } from '../enum/modals.enum';
import { wohnsinnServices } from 'App';
import ModalContext from '../context/modal.context';
import useTenantDocuments from './tenant-document.hook';
import UserContext from '../context/user.context';
import useDesiredTenant from './desired-tenant.hook';
import { handleSubscriptionDrawer } from 'core/helper/handle-subscription-drawer';
import SnackBarContext from '../context/snackbar.context';
import SubscriptionDrawerContext from '../context/subscription-drawer.context';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'core/const/routes';
import { TAnimationDirection } from 'view/static/ShareApartmentView';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import TenantFilterParamsContext from 'core/context/tenant-filter-params.context';

const useRatingHandler = (
  apartment: IApartment,
  applicationExists: boolean,
  setAnimationDirection: Dispatch<SetStateAction<TAnimationDirection>>,
  callback?: () => void
) => {
  const { handleSnackBar } = useContext(SnackBarContext);
  const { openModal } = useContext(ModalContext);
  const { t: r } = useTranslation('routes');
  const [isCreatingRating, setIsCreatingRating] = useState(false);
  const { user, isPremiumUser, tenantProfile } = useContext(UserContext);
  const { hasIncomeProofDocument, hasSchufaCheckDocument } = useTenantDocuments(tenantProfile?.uid);
  const { desiredTenantErrors } = useDesiredTenant(apartment, tenantProfile);
  const navigate = useNavigate();
  const { applicationService, mixpanelTrackingService } = wohnsinnServices;
  const { setShowSubscriptionDrawer, setShowSubscriptionLpDrawer } = useContext(SubscriptionDrawerContext);
  const queryClient = useQueryClient();
  const { tenantFilterParams } = useContext(TenantFilterParamsContext);

  const callSetAnimationDirection = (direction: TAnimationDirection) => {
    if (setAnimationDirection) {
      setAnimationDirection(direction);
    }
  };

  const submitRatingHandler = async (rating: MATCHING_MODE, skipProfileImage = false) => {
    mixpanelTrackingService.trackRatingClick(rating, apartment.id);
    callSetAnimationDirection(rating === MATCHING_MODE.NOPE ? 'left' : 'right');
    setIsCreatingRating(true);

    if (user) {
      const tenantApplicationState = getTenantApplicationState(tenantProfile);

      if (rating === MATCHING_MODE.LIKE) {
        if (!skipProfileImage && !tenantProfile?.photoUrl) {
          setTimeout(() => {
            callSetAnimationDirection('initial');
            openModal({
              id: MODAL_IDENTIFIER.UPLOAD_PROFILE_IMAGE,
              data: {
                onNext: () => {
                  submitRatingHandler(rating, true);
                },
              },
            });
          }, 300);
          setIsCreatingRating(false);
          return;
        }

        if (tenantApplicationState === TENANT_APPLICATION_STATE.NOT_READY_FOR_APPLICATION) {
          setTimeout(() => {
            callSetAnimationDirection('initial');
            openModal({ id: MODAL_IDENTIFIER.COMPLETE_APPLICATION_FOLDER });
          }, 300);
          setIsCreatingRating(false);
          return;
        }

        if (apartment?.isPro && !isPremiumUser) {
          callSetAnimationDirection('initial');
          setIsCreatingRating(false);
          handleSubscriptionDrawer(
            setShowSubscriptionLpDrawer,
            setShowSubscriptionDrawer,
            mixpanelTrackingService,
            apartment
          );
          return;
        }

        if (tenantApplicationState === TENANT_APPLICATION_STATE.MISSING_DOCS) {
          setTimeout(() => {
            callSetAnimationDirection('initial');
            openModal({ id: MODAL_IDENTIFIER.COMPLETE_DOCUMENT_FOLDER });
          }, 300);
          setIsCreatingRating(false);
          return;
        }

        if (desiredTenantErrors?.length) {
          setTimeout(() => {
            callSetAnimationDirection('initial');
            openModal({ id: MODAL_IDENTIFIER.DESIRED_TENANT, data: desiredTenantErrors });
          }, 300);
          setIsCreatingRating(false);
          return;
        }

        if (tenantApplicationState === TENANT_APPLICATION_STATE.READY_FOR_APPLICATION) {
          setTimeout(() => {
            callSetAnimationDirection('initial');
            openModal({
              id: MODAL_IDENTIFIER.BEFORE_APPLICATION_OVERVIEW,
              data: {
                finalizeApplication: () => processApplication(rating),
                hasIncomeProofDocument,
                hasSchufaCheckDocument,
              },
            });
          }, 300);
          setIsCreatingRating(false);
          return;
        }
      }

      await processApplication(rating);
    } else {
      localStorage.setItem(LOCAL_STORAGE_KEYS.APARTMENT_ID, apartment?.id);
      navigate(r(ROUTES.staticRoutes.createTenantFromApartmentApplyTunnel.path).replace(':apartmentId', apartment?.id));
      setIsCreatingRating(false);
      return;
    }
  };

  const processApplication = async (rating: MATCHING_MODE) => {
    try {
      // CREATE OR UPDATE APPLICATION DOCUMENT
      await applicationService.handleApplicationData(apartment, tenantProfile, rating, applicationExists);

      // IF RATING IS LIKE APPLY FOR APARTMENT
      if (rating === MATCHING_MODE.LIKE) {
        await applicationService.applyForApartment(apartment, tenantProfile);
        handleSnackBar('toast.success.applied', 'info');
      }

      await removeApartmentFromCache();

      setIsCreatingRating(false);
    } catch (error) {
      console.error(error);
    }
  };

  const removeApartmentFromCache = async () => {
    const data: InfiniteData<IPaginatedApartmentsListResponse> = queryClient.getQueryData([
      'matches',
      tenantFilterParams,
    ]);
    const newData = data;
    const newPages = data.pages;

    newPages.forEach((page) => {
      const index = page.apartments.findIndex((apt) => apt.id === apartment.id);
      if (index > -1) {
        page.apartments.splice(index, 1);
      }
    });
    newData.pages = newPages;

    if (callback) {
      callback();
    }
  };

  return {
    isCreatingRating,
    submitRatingHandler,
  };
};

export default useRatingHandler;
